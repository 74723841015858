const cardData = [
	{
			id:"borok",
			photo:"01_borok.jpg",
			name:"ЕЛЕНА БОРОК",
			bio:"Родилась в Беларуси в 1985 г., большую часть жизни живёт в Москве. Печаталась в журналах «Москва», «Дети Ра», «Юность». В 2014 году вошла в длинный список международной премии «Дебют». Годом ранее в соавторстве с однокурсницей Дарьей Лебедевой выпустила первую книгу стихов — «НетЛенки и ерунДашки». В 2015 году окончила Литературный институт им. М. Горького.",
			"caption":"* * *",
			poem: 
`Яблоко падает с чёрной сухой ветки.#n
Красное яблоко — оторви и брось,#n
в жерло дождя, в водовороты трав.#p 

Травы, гнутые, влажные, тянет к земле,#n
стебли перегибаются.#n
Яблоко падает, лес говорит в голос —#n
но ты не слышишь.#p

Друг мой, кто так придумал, что я всегда за тобой?#n
Кто так придумал: по свежепримятому, сочному,#n
шаг в шаг тебя повторяя,#p

ставить ступни неслышно —#n
ни защитить, ни заслонить телом; да хоть придержать локоть —#n
кто так придумал?#p
Всё, что уходит, — в землю уходит; видишь, слышишь ли?#n
Всё, что уходит — всё идёт своим чередом.#n

Я — в чёрную жирную почву корни даю,#n
ты песком рассыпаешься —#p

самое время.#n
Яблоко падает с глухим стуком,#n
каштан упал и разбился. Самое время.#p

Мы же с тобой, как все, мы — жили и умерли,#n
жили и умерли; жили и умерли врозь.#n
Теперь о чем сожалеть?`
	},
	{
			id:"korneenkova",
			photo:"02_korneenkova.jpg",
			name:"КАТЕРИНА КОРНЕЕНКОВА",
			bio:"Поэт. Журналист. Родилась в Республике Беларусь в 1984 году. Окончила Литературный институт им. А.М. Горького, семинар поэзии Станислава Куняева. Стихи печатались в журналах «Наш современник» и «Юность». А вообще, о себе всё сказала в стихах, просто читайте их, потому что они — это я.",
			caption:"Шарф",
			poem:
			`Она не любила метро,#n
Она обожала трамваи.#n
Она говорила не то,#n
Чего от нее ожидали.#p
Ходила в коротком плаще,#n
Не длила ненужные споры,#n
И шарф не носила вообще —#n
Боялась судьбы Айседоры.#p
Он был, как Есенин, курчав#n
И не был обижен талантом.#n
Так вышло, что шелковый шарф#n
Он ей повязал элегантно.#p
Края собирая узлом,#n
Случайно притронулся к коже.#n
В тот миг он не думал о том,#n
Что узел распутать не сможет.#p
Завязанный им узелок#n
У маленькой ямки под шеей#n
Красиво и правильно лег#n
И стал от тоски панацеей.`
	},
	{
			id:"lebedeva",
			photo:"03_lebedeva.jpg",
			name:"ДАРЬЯ ЛЕБЕДЕВА",
			bio:"Прозаик, поэт, критик. Родилась и живу в Москве. Окончила исторический факультет МГПУ в 2004 году, Литинститут в 2015-м (семинар детской литературы). Работала редактором, журналистом, литературным обозревателем. Ныне — тестировщик программного обеспечения. Поэзия — одно из важных, хоть и редких способов моего взаимодействия с миром. Стихи пишутся, когда необходимо емко и концентрированно выразить чувство или эмоционально проникнуть в ситуацию. Вышло два сборника стихов: «НетЛенки и ЕрунДашки» (в соавторстве с Еленой Борок, иллюстрации Марины Миллер) выпущен в 2013 году издательством «ОГИ», а «Девять недель до мая» с фотографиями Полины Сергеевой появился на свет благодаря сервису «Ridero» в 2017 году.",
			caption:"Хиросима",
			poem:
			`Если я Хиросима, то после уже, когда#n
в скверах снова шумят густые высокие кроны,#n
так шумят, будто их не касалась беда,#n
а в полях пестреют космеи и анемоны.#p
Я уже давно — территория тишины,#n
надо мной — облака пуховые и небо,#n
будто не было никогда никакой войны#n
и никто не бомбил, не сжигал и врагом мне не был.#p
Невозможно поверить в то, что была мертва,#n
там, где кости и пепел, — птиц безмятежный щебет.#n
И представить сложно, что там, где сейчас трава,#n
были только пыль, обломки и щебень.#p
Если ты захочешь лучше меня узнать,#n
покажу тебе прежнего сердца оплавленный остов.#n
Не захочешь — иди в зеленых парках гулять,#n
я тебе улыбаться буду, как тихий остров.#p
Если я Хиросима, ты ли тому виной?#n
На газонах растут цветы и в разгаре лето.#n
Приходи любоваться мной, отдыхать со мной.#n
Если я Хиросима, зачем тебе знать об этом.`
	},
	{
			id:"obodzinskaya",
			photo:"04_obodzinskaya.jpg",
			name:"ВАЛЕРИЯ ОБОДЗИНСКАЯ",
			bio:"Прозаик, музыкант, исполнитель авторских песен. После учебы в ТХТК Валерия работала двенадцать лет на фирме ФГУП «Мелодия» звукорежиссером-реставратором. В 2015 году закончила Литературный институт им. Горького. Публиковалась в журнале «Современник», в газете «Юность». В данный момент закончила биографический роман о Валерии Ободзинском, «Цунами советской эстрады», который должен появиться на прилавках в 2020 году.",
			caption:"* * *",
			poem:
			`Я с улицы, где шумное волнение,#n
Гоняет ветер удалой ручей,#n
Там где березы белой заточение,#n
Измучило плакучестью своей.#n
Я с улиц, где царит непонимание,#n
Где каждый зритель — главный режиссёр,#n
Где кто-то грустный после расставания,#n
Устало прячет свой потухший взор.#n
Где тополь, испугавшись, покачнулся.#n
И распростер нелепо руки ввысь.#n
Где горизонт испуганно очнулся,#n
И лучики рассвета занялись.#n
Я с улицы, где шлейф тумана соткан#n
Из мелкой дрожи нервного огня.#n
Где оборвался день и удивленно#n
Отправился на поиски меня.`
	},
	{
			id:"piotrovskaya",
			photo:"05_piotrovskaya.jpg",
			name:"ДАРЬЯ ПИОТРОВСКАЯ",
			bio:"Родилась в 1989 году в Москве, в семье потомственных географов. Основная профессия — журналист; работала в Щёлковском информагентстве, в газете «Вечерняя Москва», в международном онлайн-СМИ о цифровой экономике Bitnewstoday.com, в журнале «Дороги и транспорт» и в других изданиях. Лауреат премии «Северная земля», литературного форума имени Гумилёва «Осиянное слово» и других поэтических конкурсов, оценивала тексты в жюри фестиваля авторской песни «Куликово поле». Автор сборника стихов «Падающий лифт». Основные увлечения — единоборства и кинодраматургия.",
			caption:"Монолог змеи, сбежавшей из террариума в квартире ваших соседей",
			poem:
			`Тьма. Свет. Тьма.#n
Щель между звуками. Пауза – между стеной и крышей.#n
Есть тысячи способов быть проворней и тише.#n
Чёрный звук. Громкий свет фонаря за окном.#n
запах страха ползущей вдоль стенки мыши.#n
Головой – в междумирье, тело пройдет за ней.#n
Здесь существа спят.#n
Их не встревожу, вывалюсь в ночь, наружу, в черный квадрат окна,#n
В белое обрамление этой тоскливой ночи.#n
Переползу – и вниз…#n
Существа так любят ставить квадрат на квадрат,#n
Существа так смешно шипят, свиваясь в клубки –#n
Только они квадратны, а я – гад.#n
Шпагат между днём и ночью, между мирами нить.#n
Между живым и мёртвым, луной и солнцем.#n
«Будет смирным питомцем», – думали их жрецы.#n
Но разве приручишь бога вчерашней мышью?#n
О, Великая мать, ползу по пустым балконам,#n
Где-то между Египтом Верхним и Нижним.#n
Свет. Я обнаружен и обездвижен.#n
Но извернулся. Я перед вами - Наг.#n
Я Ёрмунганд. Как тебе это имя?#n
Выйду из океана и отравлю небо…#n
Медуза Горгона смотрит на Лаокоона.#n
Я – волос её и воля. Спал бы ты в детской, жрец.#n
Я кое-что понял о людях, живя в коробке.#n
Вы звали меня Анатолий. Только#n
Стоило ль так ломать свой людской язык…#n
Можно же просто Змей… (из-под кожи – зырк!)#n
Зовите – Кетцалькоатль, Царь меня назовите!#n
В жертву мне принесите спящих своих детей.#n
Я научу их знать, что в желудке птицы,#n
Я покажу, как в волка и льва вселиться,#n
Я приручу их и обращу их в змей.#n
В замочную скважину ночи#n
вставлен скрипичный ключ.#n
я выкачусь из клубка и в другой вкачусь.#n
Я стану не нитью – шаром, огненным шаром,#n
Я проглотил свой хвост. И в клетку я не хочу.#p
– Он под обшивкой! Палку! Гони, гони!#n
Дайте мешок…#n
Вдруг в мире#n
Гаснут#n
Огни.`
	},
	{
			id:"pushkarev",
			photo:"06_pushkarev.jpg",
			name:"ПАВЕЛ ПУШКАРЕВ",
			bio:"Родился и вырос на Камчатке. Первое стихотворение написал в пятом классе, первые опубликованные стихи увидел в восемнадцатилетнем возрасте. В 2008 году переехал в Москву. Публиковался в различных изданиях.",
			caption:"* * *",
			"epigraph": "Воздух пасмурный влажен и гулок…",
			"epiauthor": "О. Мандельштам",
			"poem":
			`Воздух утренний влажен и гулок,#n
Потому что вчера был туман.#n
Я в безлюдный зайду переулок,#n
Что на четверть ушёл в океан.#n
Это город под воду уходит,#n
И его нам, увы, не спасти.#n
Человек в переулок заходит,#n
Говорит переулку: «Прости».#n
Нету дома у Саши и Тани.#n
Плавал он далеко в океане,#n
Как гарпуном подстреленный кит —#n
У которого рана болит.#p
Воздух уличный гулок и влажен.#n
Я свой взор устремляю туда,#n
Где на отмель корабль посажен,#n
А команду постигла беда.#n
Нету папы у Саши и Тани,#n
Он пропал далеко в океане.#n
Таня плакала, Саша кричал.#n
Только я все молчал и молчал…`
	},
	{
			id:"skrundz",
			photo:"07_skrundz.jpg",
			name:"ТАТЬЯНА СКРУНДЗЬ",
			bio:"Родилась в 1982 г. в Липецке. Окончила Литературный институт им. Горького, Восточно-Европейский институт психоанализа (СПб). С 2010 по 2019 гг. стихи и рассказы публиковались в литературных журналах («Нева», «Новая Юность», «Новый Журнал», «Сибирские огни», «Урал», альманах «45 параллель» и мн. др.) и сборниках («Половина века» студии «Луч» и поэтов МГУ, «Антология свободного стиха 21 века» и др.). В 2016 г. вышла первая книга («Се человек», поэзия).",
			caption:"Сублимация огня",
			poem:
			`Натянутые струны бытия#n
в словах, но истолкована превратно#n
тоска о возвращении обратно#n
в миры, где пышнотелая земля#n
еще не нашинкована свинцом#n
войны, начавшейся как детская забава#n
а вылившейся в танец под венцом#n
всевышнего, что видит, как Варавва#n
освобожден, когда Христа избив#n
ведут его убийцы к исполненью#n
пророчества в божественный мотив#n
озвученный синхронно избиенью…#n
Так на Голгофе на исходе дня#n
свершается кошмар в последней фазе:#n
агапэ — сублимация огня,#n
что заточен и мечется в экстазе.`
	},
	{
			id:"stanislavskaya",
			photo:"08_stanislavskaya.jpg",
			name:"ЕЛИЗАВЕТА СТАНИСЛАВСКАЯ",
			bio:"Родилась в Магадане в 1987 году. в 2015 окончила Литературный институт имени Горького. Сейчас живет и работает в Санкт-Петербурге. В премиях и конкурсах не участвовала, есть несколько печатных публикаций.",
			caption:"кривая",
			"poem":
			`Земля холодеет.#n
С часу до четырёх нас спасёт только лодка#n
Между дворцом и дворцом#n
Прогулка.#n
Между ребрами журавля тихие реки печали.#n
Сильные когти огня в горле#n
Быстрые шаги по дождю.#n
Голова промокла#n
сквозь череп до мозга#n
головоломка.#n
Сквозь кожу по мышцам#n
Скачущие капельки#n
Мелкая соль линий.#n
Я знаю номер человека,#n
Который хочет купить значки#n
Наверное, чтобы#n
уравновесить падение.#p
Уровень затопления#n
год от года#n
становится все критичнее.#n
Все легче#n
все ближе чайки#n
над трубами из кирпича#n
Над печами.#n
Все безусловно печально#n
По-настоящему весело#p
Только преображение.#n
Лишь#n
волны, водоросли и звуки.`
	},
	{
			id:"khaddadin",
			photo:"09_khaddadin.jpg",
			name:"ВАЛЕРИЯ ХАДДАДИН",
			bio:"Первое образование — системное программирование; второе — востоковедение, арабский язык; следующее — литературный работник, поэзия (мастерская И. Л. Волгина); в настоящее время — аспирант Отделения философии, религиоведения и этики Литературного института им. А. М. Горького. Печаталась в журналах «Юность», «Дети Ра», региональной периодике, автор поэтического сборника «Первые точки над i», 2017 год. Лауреат всероссийского конкурса «Осиянное слово» им. Н. Гумилёва 2016 года (Гран-при, секция поэзии); лауреат Международной Бунинской премии 2017 года (шорт лист). Опубликовать свои стихи в этом сборнике, рядом со стихами моих однокурсников, особенное для меня удовольствие и радость.",
			caption:"* * *",
			"epigraph": "«Философия есть упражнение в смерти»",
			"epiauthor": "Карл Ясперс",
			"poem":
			`Философия есть упражнение в смерти:#n
когда Ваш вычищенный Pal Zileri#n
висит рядом с моим пальтишком, давайте сверим#n
наши часы. Фэн-шуй не изменит меры —#n
старости и болезней.#p
Быть полюбезней — не значит бежать их,#n
а значит — просто бежать.#n
С Бреговичем ли, Платоном,#n
мольбертом, в конце концов,#n
за человеческим, высшим — за творческим.#p
Бег, философия — путь. И не свернуть,#n
хоть раскорячься на стремени.#n
Стрелы Зенона, в теории#n
не поспевая за временем, бьют наповал.#p
Стоик сказал:#n
«Ахиллесу никак не догнать черепаху»,#n
он по-соседски с божками общался на тверди.#n
Мы — упражняемся в Боге,#n
а значит, всегда упражняемся в смерти.`
	},
	{
			id:"chuyasheva",
			photo:"10_chuyasheva.jpg",
			name:"ДИАНА ЧУЯШЕВА",
			bio:"Поэт, юрист, многодетная мама. Награждена медалью ордена «Родительская слава» Указом Президента РФ В.В. Путина в 2019 г. Выпускница Литературного института им. А. М. Горького (мастерская И.Л. Волгина). Автор книги «Тихая любовь», 2013, аудиокниги «Ливень», 2020, один из авторов книг «Половина века», 2019, «Зачётная книжка», 2019, «Новая волна», 2007. Печаталась в журналах «Юность», «Тверской бульвар, 25», газетах «Поэтоград», «Экологический набат» и др. Финалист литературного форума «Осиянное слово» им. Н. Гумилёва, четвертьфиналист серии поэтических дуэлей «Слово за слово».	Живёт в Москве.",
			caption:"* * *",
			"poem":
			`Всё отдала тебе. Всю себя.#n
			На удивление...#n
			Инопланетный мой. Из декабря.#n
			Из поколения,#n
			Где мы не знали о соцсетях,#n
			Селфи и прочем, но#n
			Так хорошо на душе у тебя...#n
			Не замороченно.#n
			Сколько десятков уже разменял?#n
			Чувства озимого?#n
			Что же так долго искал меня?#n
			Здравствуй, родимый мой...#n
			Синее пламя небесных глаз#n
			Греет, не жгучее...#p
			Боже, есть кто-то счастливее нас?#n
			И невезучее...`
	},
	{
			id:"ushmanova",
			photo:"11_ushmanova.jpg",
			name:"ВАРВАРА ЮШМАНОВА",
			bio:"Родилась в Братске. Жила в разных городах России. Окончила Ульяновский государственный университет по специальности «журналистика». Выпускница Литературного института им. А. М. Горького (семинар поэзии Игоря Волгина). Публиковалась в нескольких поэтических сборниках, журналах «Юность», «Кольцо А», «Нева», «Волга — XXI век», «День и ночь» и других. Финалист Международного литературного Волошинского конкурса. Лауреат премии имени Риммы Казаковой «Начало». Автор книги стихотворений «Жизнь около» (2017, «Водолей»). Живет в Москве.",
			caption:"* * *",
			poem:
			`Полароид выцвел, но храню.#n
Папины усы, спина прямая.#n
Маму молодую обнимая,#n
Он подобен богу и огню.#n
А ребёнок — куколка, цветок.#n
Разве шьют теперь такие платья?!#n
Это фото — вечное объятье —#n
Мой сердечный клапан, кровоток.`
	}
]

export default cardData;